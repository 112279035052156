
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import MandatoryFieldFlag from "@/components/Form/MandatoryFieldFlag.vue";

@Options({
  components: {
    MandatoryFieldFlag,
  },
  props: {
    /**
     * The current value
     */
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
    /**
     * The label for the input
     */
    label: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The placeholder for the input
     */
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The help text for the input
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The input type
     * @values text, email, number, password, search, tel, url
     */
    type: {
      type: String as PropType<
        "text" | "email" | "number" | "password" | "search" | "tel" | "url"
      >,
      required: false,
      default: "text",
    },
    /**
     * The maximum number of characters the user can enter into the input
     */
    maxLength: {
      type: Number as PropType<number>,
      required: false,
    },
    /**
     * A regular expression the input's value should match
     */
    pattern: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the input is disabled
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the input is readonly
     */
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The tab index of the input
     */
    tabIndex: {
      type: Number as PropType<number>,
      required: false,
    },
  },
  data() {
    return {
      invalid: false,
    };
  },
  methods: {
    /**
     * Set focus on input
     */
    focus() {
      this.$refs.input.focus();
    },

    /**
     * Gets called when the user updates value
     *
     * @param {Event} event The event
     */
    onInput(
      event: InputEvent & {
        target: HTMLInputElement;
      }
    ): void {
      this.$emit("update:value", event.target.value);

      if (this.pattern) {
        const regExp = new RegExp(this.pattern);

        this.invalid = !regExp.test(event.target.value);

        if (this.invalid) {
          this.setValidityMessage(this.validityMessage || "Invalid input");
        } else {
          this.setValidityMessage("");
        }

        this.setInputFilter(event.target, (value: string) =>
          regExp.test(value)
        );
      }
    },

    /*
     * Validates user input by setting an input filter.
     */
    setInputFilter(
      textbox: Element,
      inputFilter: (value: string) => boolean
    ): void {
      [
        "input",
        "keydown",
        "keyup",
        "mousedown",
        "mouseup",
        "select",
        "contextmenu",
        "drop",
      ].forEach(function (event) {
        textbox.addEventListener(
          event,
          function (
            this: (HTMLInputElement | HTMLTextAreaElement) & {
              oldValue?: string;
              oldSelectionStart?: number | null;
              oldSelectionEnd?: number | null;
            }
          ) {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
              this.value = this.oldValue || "";
              if (
                this.oldSelectionStart !== null &&
                this.oldSelectionEnd !== null
              ) {
                this.setSelectionRange(
                  this.oldSelectionStart ?? 0,
                  this.oldSelectionEnd ?? 0
                );
              }
            } else {
              this.value = "";
            }
          }
        );
      });
    },

    /**
     * Sets a custom validity message.
     *
     * @param {string} message The message to set.
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
  },
})
export default class TextInput extends Vue {}
